import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Platform } from 'react-native'
import CreatingOasisScreen from '../components/onboarding/CreatingOasisScreen'
import { UserProfileProvider } from '../providers/UserProfileProvider'
import ActivityScreen from './ActivityScreen'
import AdminScreen from './AdminScreen'
import AudioPlayerScreen from './AudioPlayerScreen'
import BookmarksScreen from './BookmarksScreen'
import CollectionScreen from './CollectionScreen'
import ExploreScreen from './explore/ExploreScreen'
import FullPDFScreen from './explore/FullPDFScreen'
import GroupCalendarScreen from './explore/GroupCalendarScreen'
import SearchScreen from './explore/SearchScreen'
import HomeScreen from './HomeScreen'
import ModeratorDetailScreen from './moderators/ModeratorDetailScreen'
import ModeratorsScreen from './moderators/ModeratorsScreen'
import NotFoundScreen from './NotFoundScreen'
import PersonalizationScreen from './onboarding/PersonalizationScreen'
import VerifyAccessCodeScreen from './onboarding/VerifyAccessCodeScreen'
import VerifyEligibilityScreen from './onboarding/VerifyEligibilityScreen'
import AboutScreen from './profile/AboutScreen'
import AccountScreen from './profile/AccountScreen'
import EAPScreen from './profile/EAPScreen'
import HealthMinutesScreen from './profile/HealthMinutesScreen'
import InsightsScreen from './profile/InsightsScreen'
import LeaderboardScreen from './profile/LeaderboardScreen'
import MyGroups from './profile/MyGroupsScreen'
import SelectRoleScreen from './profile/SelectRoleScreen'
import VibeCheckHistoryScreen from './profile/VibeCheckHistoryScreen'
import VibeCheckReviewScreen from './profile/VibeCheckReviewScreen'
import ProfileScreen from './ProfileScreen'
import ReferralScreen from './ReferralScreen'
import RoomScreen from './RoomScreen'
import VibeCheckScreen from './VibeCheckScreen'
import BalancedSelfScreen from './voyages/BalancedSelfScreen'
import CompassFormScreen from './voyages/CompassFormScreen'
import CompassResultsScreen from './voyages/CompassResultsScreen'
import CompassScreen from './voyages/CompassScreen'
import CompletedScreen from './voyages/CompletedScreen'
import FocusAreaScreen from './voyages/FocusAreaScreen'
import LandingVoyageScreen from './voyages/LandingVoyageScreen'
import PathScreen from './voyages/PathScreen'
import VoyagesIntroScreen from './voyages/VoyagesIntroScreen'
import VoyagesScreen from './voyages/VoyagesScreen'
import VoyagesScreenResultsScreen from './voyages/VoyagesScreenerResultsScreen'
import VoyagesScreenerScreen from './voyages/VoyagesScreenerScreen'

export type MemberScreenParams = {
  Home: undefined
  Activity: { activityId?: string; stepId?: string }
  Admin: undefined
  Ochsner: undefined
  Explore: undefined
  Profile: undefined
  Personalization: undefined
  Room: undefined
  Search: {
    categoryFilters?: number
    timeFilters?: number
    searchValue?: string
  }
  VibeCheck: undefined
  VibeReview: {
    picked: string
    comment: string
    date: string
  }
  VibeCheckHistory: undefined
  MyGroups: undefined
  HealthMinutes: undefined
  EAP: undefined
  About: undefined
  Account: undefined
  AudioPlayer: undefined
  FullPDF: undefined
  NotFound: undefined
  LiveGroups: undefined
  RoleReview: undefined
  MentalHealth: undefined
  CreatingOasis: undefined
  Insights: undefined
  '404': undefined
  Referral: undefined
  Bookmarks: undefined
  Moderators: undefined
  ModeratorDetail: {
    moderatorId: string
  }
  Voyage: undefined
  VoyageScreener: undefined
  VoyageScreenerResults: undefined
  VoyageIntro: undefined
  LandingVoyage: undefined
  BalancedSelf: undefined
  FocusArea: {
    focusArea: string
  }
  Path: {
    focusArea: string
    id: string
  }
  Completed: undefined
  Compass: undefined
  CompassForm: undefined
  CompassResults: undefined
  ChangePassword: undefined
  VerifyEligibility: undefined
  VerifyAccessCode: undefined
  Collection: undefined
  Leaderboard: undefined
}

const MemberScreen = () => {
  const Stack = createNativeStackNavigator<MemberScreenParams>()

  return (
    <UserProfileProvider>
      <Stack.Navigator
        initialRouteName="Home"
        screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="Activity"
          component={ActivityScreen}
          initialParams={{ activityId: undefined }}
        />
        <Stack.Screen
          name="Admin"
          component={AdminScreen}
          options={{ animation: 'none' }}
        />
        <Stack.Screen
          name="Explore"
          component={ExploreScreen}
          options={{ animation: 'none' }}
        />
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{ animation: 'fade' }}
        />
        <Stack.Screen name="LiveGroups" component={GroupCalendarScreen} />
        <Stack.Screen
          name="Personalization"
          component={PersonalizationScreen}
          options={{ animation: 'none' }}
        />
        <Stack.Screen
          name="Profile"
          component={ProfileScreen}
          options={{ animation: 'none' }}
        />
        <Stack.Screen name="RoleReview" component={SelectRoleScreen} />
        <Stack.Screen name="Room" component={RoomScreen} />
        <Stack.Screen
          name="Search"
          component={SearchScreen}
          options={{ animation: 'none' }}
        />
        <Stack.Screen name="VibeCheck" component={VibeCheckScreen} />
        <Stack.Screen name="VibeReview" component={VibeCheckReviewScreen} />

        <Stack.Screen name="404" component={NotFoundScreen} />
        <Stack.Screen name="About" component={AboutScreen} />
        <Stack.Screen name="Account" component={AccountScreen} />
        <Stack.Screen name="AudioPlayer" component={AudioPlayerScreen} />
        <Stack.Screen
          name="CreatingOasis"
          component={CreatingOasisScreen}
          options={{ animation: 'fade' }}
        />
        <Stack.Screen name="EAP" component={EAPScreen} />
        <Stack.Screen name="FullPDF" component={FullPDFScreen} />
        <Stack.Screen name="MentalHealth" component={HealthMinutesScreen} />
        <Stack.Screen name="MyGroups" component={MyGroups} />
        <Stack.Screen
          name="VibeCheckHistory"
          component={VibeCheckHistoryScreen}
        />
        <Stack.Screen name="Insights" component={InsightsScreen} />
        <Stack.Screen name="Referral" component={ReferralScreen} />
        <Stack.Screen name="Bookmarks" component={BookmarksScreen} />
        <Stack.Screen name="Moderators" component={ModeratorsScreen} />
        <Stack.Screen
          name="ModeratorDetail"
          component={ModeratorDetailScreen}
        />
        <Stack.Screen name="Voyage" component={VoyagesScreen} />
        <Stack.Screen name="VoyageScreener" component={VoyagesScreenerScreen} />
        <Stack.Screen
          name="VoyageScreenerResults"
          component={VoyagesScreenResultsScreen}
        />
        <Stack.Screen name="VoyageIntro" component={VoyagesIntroScreen} />
        <Stack.Screen name="LandingVoyage" component={LandingVoyageScreen} />
        <Stack.Screen name="BalancedSelf" component={BalancedSelfScreen} />
        <Stack.Screen name="FocusArea" component={FocusAreaScreen} />
        <Stack.Screen name="Path" component={PathScreen} />
        <Stack.Screen name="Completed" component={CompletedScreen} />
        <Stack.Screen name="Compass" component={CompassScreen} />
        <Stack.Screen name="CompassForm" component={CompassFormScreen} />
        <Stack.Screen name="CompassResults" component={CompassResultsScreen} />
        <Stack.Screen
          name="VerifyEligibility"
          component={VerifyEligibilityScreen}
        />
        <Stack.Screen
          name="VerifyAccessCode"
          component={VerifyAccessCodeScreen}
        />
        <Stack.Screen name="Collection" component={CollectionScreen} />
        <Stack.Screen name="Leaderboard" component={LeaderboardScreen} />
      </Stack.Navigator>
    </UserProfileProvider>
  )
}

const memberLinks = {
  Activity: '/activity',
  Admin: '/admin',
  Explore: '/explore',
  Home: '/home',
  LiveGroups: '/live-groups',
  Personalization: '/personalization',
  Profile: '/profile/:subscreen',
  RoleReview: '/role-review',
  Room: '/room/:roomId',
  Search: '/search',
  VibeCheck: '/vibe-check',
  VibeReview: '/vibe-review',
  Moderators: '/moderators',
  ModeratorDetail: '/moderators/:moderatorId',
  Voyage: '/voyage',
  VoyageScreener: '/voyage/screener',
  VoyageScreenerResults: '/voyage/screener/results',
  VoyageIntro: '/voyage/video-intro',
  LandingVoyage: '/voyage/landing',
  BalancedSelf: '/voyage/balanced-self',
  FocusArea: '/voyage/focus-area/:focusArea',
  Path: '/voyage/path/:id',
  Completed: '/voyage/completed',
  Compass: '/voyage/compass',
  CompassForm: '/voyage/compass/values',
  CompassResults: '/voyage/compass/results',
  ChangePassword: '/profile/change-password',
  VerifyEligibility: '/verify-eligibility',
  VerifyAccessCode: '/verify-account',
  Collection: '/collection/:slug',
  404: '*',
  Referral: `${Platform.OS === 'ios' && '/profile/referrals'}`,
}

export { MemberScreen, memberLinks }
