import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useUserProfileContext } from '../providers/UserProfileProvider'

export default (message) => {
  const { boot, show, update, showNewMessage } = useIntercom()
  const { userProfile } = useUserProfileContext()

  const openChat = useCallback(() => {
    if (
      userProfile?.email &&
      userProfile?.id &&
      userProfile?.intercomHmacValue &&
      (userProfile?.goal || userProfile?.customGoal) &&
      userProfile?.professionalRoles
    ) {
      boot({
        email: userProfile.email,
        userId: userProfile.id,
        hideDefaultLauncher: true,
        userHash: userProfile.intercomHmacValue,
        customAttributes: {
          professionalRoles: JSON.stringify(userProfile.professionalRoles),
          goal: userProfile.goal ?? userProfile.customGoal,
        },
      })
      if (message) {
        showNewMessage(message)
      } else {
        show()
      }
    } else {
      boot()
      if (message) {
        showNewMessage(message)
      } else {
        show()
      }
    }
  }, [boot, show, userProfile])

  return {
    openChat,
    update,
  }
}
