import { Button, Divider, Flex, HStack, Text } from 'native-base'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import ActivityCard from '../../_shared/card/ActivityCard'
import FaIcon from '../../_shared/FaIcon'

const PathStep = ({
  step,
  index,
  firstIncompleteStep,
  goToActivity,
  dataLength,
}) => {
  const isDesktop = useDesktopBreakpoint()
  return (
    <HStack key={index} space="4">
      <Flex>
        <Flex flex="1" mb="2">
          {index > 0 && (
            <Divider bg="muted.300" orientation="vertical" w="0.5" mx="auto" />
          )}
        </Flex>
        <HStack
          justifyContent="center"
          alignItems="center"
          borderColor={step.completed ? 'white' : 'secondary.500'}
          borderWidth={1}
          bg={step.completed ? 'secondary.500' : 'white'}
          borderRadius="full"
          width={6}
          height={6}>
          {step.completed ? (
            <FaIcon name="check" color="white" size={12} />
          ) : (
            <Text color={step.completed ? 'white' : 'secondary.500'}>
              {index + 1}
            </Text>
          )}
        </HStack>
        <Flex flex="1" mt="2">
          {index < dataLength - 1 && (
            <Divider bg="muted.300" orientation="vertical" w="0.5" mx="auto" />
          )}
        </Flex>
      </Flex>
      <Flex flex="1" my="2.5">
        <ActivityCard
          activity={step}
          onPress={
            firstIncompleteStep >= index ? () => goToActivity(step.id) : null
          }
        />
        {firstIncompleteStep === index && (
          <Button
            alignSelf="center"
            width={isDesktop ? '326px' : '100%'}
            mt={2}
            onPress={async () => goToActivity(step.id)}>
            <Text fontSize="16px" fontWeight={500} color="white">
              Start lesson
            </Text>
          </Button>
        )}
      </Flex>
    </HStack>
  )
}

export default PathStep
