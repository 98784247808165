import {
  Box,
  Center,
  Heading,
  HStack,
  ScrollView,
  StatusBar,
  Text,
} from 'native-base'
import { useEffect, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import {
  ActivityRecommendationState,
  ActivityType,
} from '../../../domain/models/interfaces/activityInterfaces'
import analytics from '../../../domain/services/analytics/AnalyticsService'
import BugSnagService from '../../../domain/services/bugsnag/BugSnagService'
import clevertap from '../../../domain/services/clevertap/ClevertapAdapter'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../domain/services/useBreakpoint'
import ActivityModalPreview from '../components/explore/activities/ActivityModalPreview'
import HomeDesktopView from '../components/home/HomeDesktopView'
import HomeImageBackground from '../components/home/HomeImageBackground'
import HomeMobileView from '../components/home/HomeMobileView'
import MinutesProgress from '../components/home/MinutesProgress'
import OasisSelect from '../components/home/OasisSelect'
import { useGetDailyRecs } from '../components/home/queries'
import OasisThemeForm from '../components/onboarding/OasisThemeForm'
import ActivityCard from '../components/_shared/card/ActivityCard'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import MinutesProgressBar from '../components/_shared/MinutesProgressBar'
import BottomSheet from '../components/_shared/notification/BottomSheet'
import { SkeletonLoader } from '../components/_shared/SkeletonLoader'
import DefaultLayout from '../layouts/DefaultLayout'
import { useUserProfileContext } from '../providers/UserProfileProvider'
import { useGetLiveAndUpcoming } from '../queries/activitiesQueries'

const HomeScreen = ({ navigation }) => {
  const { userProfile, isFetched } = useUserProfileContext()
  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()
  const window = useWindowDimensions()
  const { height, width } = useWindowDimensions()
  const { liveAndUpcoming } = useGetLiveAndUpcoming()

  const expectedMinutes = userProfile?.mentalHealthMinutes * 7
  const minutes = userProfile?.healthMinutesProgress
  const oasis = userProfile?.oasis

  const [oasisModalShown, setOasisModalShown] = useState(false)
  const [minutesModalShown, setMinutesModalShown] = useState(false)

  const { selectOasis } = useUserProfileContext()
  const { dailyRecs } = useGetDailyRecs()
  const [modalData, setModalData] = useState(null)
  const [modalShown, setModalShown] = useState(false)
  const handleCloseModal = () => setModalShown(false)

  useEffect(() => {
    if (Platform.OS === 'web' && userProfile.id) {
      BugSnagService.setUser(userProfile)
    }
  }, [userProfile])

  const onPressActivity = (activity) => {
    if (activity.type === ActivityType.VIBE_CHECK) {
      if (activity.recommendation === ActivityRecommendationState.COMPLETED)
        return navigation.navigate('VibeReview', {
          picked: activity.mood,
          comment: activity.note,
          date: activity.createdAt,
        })
      return navigation.navigate('VibeCheck')
    }

    setModalData(activity)
    setModalShown(true)
  }

  const onSelectOasis = (oasis) => {
    selectOasis(oasis)
    setOasisModalShown(false)
  }

  const checklistItems = dailyRecs?.map((activity) => (
    <Box maxW={isMobile ? '100%' : undefined}>
      <ActivityCard
        activity={activity}
        onPress={() => onPressActivity(activity)}
      />
    </Box>
  ))

  const checklistConditions = dailyRecs?.map(
    (activity) =>
      activity.recommendation === ActivityRecommendationState.COMPLETED
  )

  const bgHeight = isMobile
    ? Platform.OS === 'ios'
      ? window.height * 0.4
      : '40%'
    : ''

  const [headerState, setHeaderState] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setHeaderState((200 * 3) / 2)
    }, 1000)
  }, [setHeaderState])

  useEffect(() => {
    if (isFetched) {
      clevertap.registerForPush()
      analytics.track('Home Reached')
    }
  }, [isFetched])

  return (
    <DefaultLayout bg="muted.50" activeLabel="Today">
      <StatusBar barStyle="light-content" />
      <HomeImageBackground>
        <FixedContainer
          size="lg"
          props={{
            height: 112,
          }}>
          <HStack
            mt={Platform.OS === 'ios' ? '2' : '6'}
            mb={isMobile ? 'auto' : '6'}
            mx={isDesktop ? '0' : '4'}
            alignItems="center"
            w={isMobile ? width - 32 : undefined}
            justifyContent="space-between"
            safeAreaTop>
            <HStack alignItems="center">
              {isFetched && (
                <OasisSelect onPress={() => setOasisModalShown(true)} />
              )}
              <Heading color="white">Welcome</Heading>
            </HStack>
            <Box>
              {isFetched ? (
                <MinutesProgress
                  key={expectedMinutes}
                  doneMinutes={minutes}
                  expectedMinutes={expectedMinutes}
                  onPress={() => {
                    setMinutesModalShown(true)
                  }}
                />
              ) : (
                <SkeletonLoader size={{ width: '100%', height: 300 }} />
              )}
            </Box>
          </HStack>
        </FixedContainer>
        {!isMobile && (
          <HomeDesktopView
            isFetched={isFetched}
            upcomingGroups={liveAndUpcoming?.slice(0, 5)}
            checklistItems={checklistItems}
            checklistConditions={checklistConditions}
          />
        )}
      </HomeImageBackground>

      {isMobile && (
        <ScrollView
          h={height - 200}
          showsVerticalScrollIndicator={false}
          borderRadius="2xl"
          mt="112px"
          snapToOffsets={[0, 200]}>
          <HomeMobileView
            upcomingGroups={liveAndUpcoming?.slice(0, 5)}
            checklistItems={checklistItems}
            checklistConditions={checklistConditions}
          />
        </ScrollView>
      )}
      <BottomSheet
        body={<OasisThemeModal oasis={oasis} onSelectOasis={onSelectOasis} />}
        isOpen={oasisModalShown}
        title="Your Oasis"
        handleClose={() => setOasisModalShown(false)}
        hasCloseOnBottom={true}
      />
      <BottomSheet
        body={
          <ModalMinutesProgress
            doneMinutes={minutes}
            expectedMinutes={expectedMinutes}
          />
        }
        isOpen={minutesModalShown}
        title="Mental health minutes"
        handleClose={() => setMinutesModalShown(false)}
        hasCloseOnBottom={true}
      />
      <ActivityModalPreview
        modalData={modalData}
        modalShown={modalShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
          width: 400,
          top: !isMobile ? '0px' : null,
          hasCloseOnBottom: false,
        }}
      />
    </DefaultLayout>
  )
}

const OasisThemeModal = ({ oasis, onSelectOasis }) => {
  return (
    <Box mt={'10px'} mb={'110px'} alignSelf="center">
      <OasisThemeForm value={oasis} setValue={onSelectOasis} isInModal />
    </Box>
  )
}

const ModalMinutesProgress = ({ doneMinutes, expectedMinutes }) => {
  return (
    <Box mt={'10px'} mb={'110px'} alignSelf="center">
      <MinutesProgressBar
        doneMinutes={doneMinutes}
        expectedMinutes={expectedMinutes}
      />
      <Center>
        <Text py={'8px'} fontWeight="bold" color="secondary.500" fontSize="lg">
          {doneMinutes}/{expectedMinutes} minutes
        </Text>
        <Text fontWeight="light" textAlign="center" maxW={270}>
          Track your progress and work toward your weekly goal!
        </Text>
      </Center>
    </Box>
  )
}

export default HomeScreen
