import RoomContainer from '../components/room/RoomContainer'
import { CabanaSocketProvider } from '../providers/CabanaSocketProvider'
import { RoomClientProvider } from '../providers/RoomClientProvider'
import { RoomProvider } from '../providers/RoomProvider'
import { TwilioRoomProvider } from '../providers/TwilioRoomProvider'

const RoomScreen = ({ route }) => {
  return (
    <CabanaSocketProvider>
      <RoomProvider
        id={route.params.roomId}
        activityId={route.params.activityId}>
        <RoomClientProvider>
          <TwilioRoomProvider>
            <RoomContainer />
          </TwilioRoomProvider>
        </RoomClientProvider>
      </RoomProvider>
    </CabanaSocketProvider>
  )
}

export default RoomScreen
