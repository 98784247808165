import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, HStack, Text } from 'native-base'
import { useCompass } from '../../../queries/voyageQueries'
import { MemberScreenParams } from '../../../screens/MemberScreen'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import CompassValues from './CompassValues'

const CompassCompleted = ({ imageSize }) => {
  const { data, isLoading } = useCompass()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()

  return (
    <Box
      mb={4}
      borderRadius="20px"
      bg="white"
      p={4}
      borderColor="grey.200"
      borderWidth={1}>
      {isLoading && (
        <SkeletonLoader size={{ width: '100%', height: '300px' }} />
      )}
      {data && (
        <>
          <HStack alignItems="center" justifyContent="space-between">
            <Text color="primary.900" fontSize="20px" fontWeight={500}>
              Inner Compass
            </Text>
            <Button
              onPress={() => navigation.navigate('Compass')}
              p={0}
              height="30px"
              width="70px"
              variant="unstyled"
              borderColor="grey.200"
              borderWidth={1}
              borderRadius="8">
              <Text fontSize="12px" fontWeight={600}>
                Details
              </Text>
            </Button>
          </HStack>
          <CompassValues data={data} imageSize={imageSize} />
          <Text color="primary.900" mt={2}>
            You feel best when your behavior and life choices align with your
            values.
          </Text>
        </>
      )}
    </Box>
  )
}

export default CompassCompleted
