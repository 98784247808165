import { Box, Text } from 'native-base'
import { useEffect, useState } from 'react'
import AttendeeState from '../../../../../domain/enums/AttendeeState'
import { useChat } from '../../../../../domain/services/useChat'
import { CHAT, INFO, PEOPLE } from '../../../constants/roomConstants'
import { useFeatures } from '../../../providers/FeatureProvider'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import Tabs from '../../_shared/layout/Tabs'
import Chat from '../Chat'
import Roster from '../Roster'
import InfoTab from './InfoTab'

const RoomMobileTabs = ({ tabSelected, selectTab }) => {
  const room = useRoomContext()
  const { features } = useFeatures()
  const [messages, sendMessage] = useChat(room.roomId)
  const [otherUserMessageCount, setOtherUserMessageCount] = useState(0)
  const roomClient = useRoomClientContext()

  useEffect(() => {
    const otherUserMessages = messages.filter(
      (m) => m.user !== 'SYSTEM' && m.user !== room.me.userId
    )
    setOtherUserMessageCount(otherUserMessages.length)
  }, [messages])

  const unreadMessageCount = Math.max(
    otherUserMessageCount - roomClient.lastReadIndex,
    0
  )

  const tabsData = {
    [PEOPLE]: {
      title: `People (${
        room.attendees.filter((a) => a.state === AttendeeState.Joined).length
      })`,
      content: <Roster />,
    },
    ...(features.GROUP_TECH_SUPPORT && {
      [INFO]: {
        title: 'Info',
        content: <InfoTab room={room} />,
      },
    }),
    [CHAT]: {
      title: 'Chat',
      content: (
        <Chat
          messages={messages}
          sendMessage={sendMessage}
          otherUserMessageCount={otherUserMessageCount}
        />
      ),
      secondaryComponent: unreadMessageCount ? (
        <Box backgroundColor={'muted.500'} borderRadius={35}>
          <Text color={'white'} px={3} fontWeight="medium">
            {unreadMessageCount}
          </Text>
        </Box>
      ) : null,
      onOpenCallback: roomClient.openChat,
    },
  }

  return (
    <Tabs tabSelected={tabSelected} selectTab={selectTab} tabsData={tabsData} />
  )
}

export default RoomMobileTabs
