import { Button, HStack, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { Activity } from '../../../../../domain/models/interfaces/activityInterfaces'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useLike } from '../../../queries/activitiesQueries'
import FaIcon from '../../_shared/FaIcon'

interface LikeButton {
  bottom: number
  activity: Activity
  left?: number
}

const LikeButton = ({ bottom, activity, left }: LikeButton) => {
  const { like, unlike } = useLike(activity.id)
  const isMobile = useMobileBreakpoint()
  const windowWidth = useWindowDimensions().width
  const buttonWidth = 136

  const handleLike = () => {
    if (activity.liked) {
      unlike()
    } else {
      like()
    }
  }
  return (
    <Button
      w="136px"
      borderRadius="48px"
      bg={activity.liked ? 'primary.700' : 'muted.100'}
      onPress={handleLike}
      variant="unstyled"
      height="44px"
      px={6}
      bottom={bottom}
      left={isMobile ? (windowWidth - buttonWidth) / 2 : left}
      position="absolute">
      <HStack alignItems="center">
        <FaIcon
          name="thumbs-up"
          color={activity.liked ? 'primary.50' : 'primary.900'}
        />
        <Text ml={2} color={activity.liked ? 'primary.50' : 'primary.900'}>
          {activity.liked ? 'I liked this' : 'Like this'}
        </Text>
      </HStack>
    </Button>
  )
}

export default LikeButton
