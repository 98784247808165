import Collection from '../components/collection/Collection'
import { useFeatures } from '../providers/FeatureProvider'
import NotFoundScreen from './NotFoundScreen'

const CollectionScreen = ({ route }) => {
  const { slug } = route.params
  const { features } = useFeatures()

  if (!features.COLLECTIONS) return <NotFoundScreen />
  return <Collection slug={slug} />
}

export default CollectionScreen
